@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,300;1,400;1,500&family=Neucha&family=Open+Sans:ital,wght@0,400;1,300;1,600&family=Oswald:wght@300&family=Roboto+Mono&family=Victor+Mono:wght@700&family=Ysabeau+Infant:ital@1&display=swap");

.login_loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* buttons in the Menu */
.button_wrapper {
  display: flex;
  flex-direction: column;
}
.button_wrapper :first-child {
  font-size: 0.8rem;
}
.button_wrapper :nth-child(2) {
  font-size: 0.5rem;
}

/* Action Page */
.worker_wrapper .rpv-core__page-layer {
  margin: 0px;
}

/* Flagging Page */
.report_description {
  border-left: 4px solid #888;
  padding: 20px 20px 20px 12px;
  font-style: italic;
  margin: 10px 0;
}
.tableCard p {
  font-size: 0.8rem;
}

/* TweetCard */
.tweet_icons_text {
  margin-left: 8px;
  color: rgb(83, 100, 113);
  font-size: 14px;
  font-weight: bolder;
}

.x-logo {
  width: 38px;
  height: 38px;
}

.tweet_icons_wrapper {
  display: flex;
  align-items: center;
}

.verified_icon {
  width: 19px;
  height: 19px;
  margin-left: 4px;
}

.follow_button {
  color: rgb(0, 111, 214);
  font-weight: bold;
  font-size: 15px;
}

.dot_separator {
  color: rgb(83, 100, 113);
  font-weight: 400;
  padding: 0 5px;
}
